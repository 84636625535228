<template>
  <div class="app-container merchant-management-list">
    <div class="button-line">
      <r-button plain @click="addNewConfig">新增配置</r-button>
      <r-button @click="initDatas">刷新</r-button>
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <MerchantManageDialog
      :dataset="merchantDialog"
      v-if="merchantDialog.show"
      :paymentMethods="paymentMethods"
      @close="dialogClose"
    />
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
import MerchantManageDialog from "./components/merchantManageDialog.vue";
import { getLoopUpItem } from "@/api/business/base/tenant/device";
import { getMerchantList, deleteMerchant } from "@/api/ruge/lego/config";
export default {
  name: "merchant-management-list",
  components: {
    MerchantManageDialog,
    FinalTable,
  },
  data() {
    return {
      loadingFlag: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: false,
        },
        header: [
          { prop: "mchName", label: "商户号描述", width: "" },
          { prop: "payType", label: "支付类型", width: "" },
          { prop: "appId", label: "小程序appId", width: "" },
          { prop: "mchId", label: "商户号", width: "" },
          { prop: "updateBy", label: "修改人", width: "" },
          { prop: "updateTime", label: "修改时间", width: "" },
          { prop: "operation", label: "操作", width: "120" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          updateTime: {
            type: "dateFormat",
          },
          payType: {
            type: "enumerationColumn",
            emuList: {
              // CLOUD: "云端",
              // EDGE: "边缘",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      filterObj: {
        current: 1,
        rowCount: 10,
      },
      merchantDialog: {
        show: false,
        datas: null,
      },
      paymentMethods: [],
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.initDatas();
    this.initPaymentMethods();
  },
  methods: {
    initPaymentMethods() {
      getLoopUpItem({
        classifyCode: "MCH_PAY_TYPE",
      }).then((res) => {
        let tempMap = {};
        this.paymentMethods = res;
        res.forEach((item) => {
          tempMap[item.itemCode] = item.itemName;
        });
        this.dataset.detailConfig.payType.emuList = tempMap;
      });
    },
    initDatas() {
      this.loadingFlag = true;
      getMerchantList(this.filterObj)
        .then((res) => {
          this.dataset.tableData = res.data.rows;
          this.dataset.pageVO.total = res.data.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    addNewConfig() {
      this.merchantDialog.show = true;
      this.merchantDialog.datas = null;
    },
    editHandler(rowData) {
      this.merchantDialog.show = true;
      this.merchantDialog.datas = rowData;
    },
    dialogClose(flag) {
      this.merchantDialog.show = false;
      flag === true && this.initDatas();
    },
    async deleteHandler({ id }) {
      await this.$confirm(
        `确定删除该条商户信息吗？`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: "再想想",
          type: "warning",
        }
      );
      deleteMerchant(id).then(() => {
        this.$message.success("删除成功！");
        this.initDatas();
      });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.filterObj.current = 1;
        this.filterObj = { ...this.filterObj, ...datas.params };
        this.initDatas();
      } else if (datas.type === "paginationChange") {
        this.filterObj.current = datas.params.current.page;
        this.filterObj.rowCount = datas.params.current.limit;
        this.initDatas();
      }
      if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editHandler(datas.row);
            break;
          case "delete":
            this.deleteHandler(datas.row);
            break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.merchant-management-list {
  .button-line {
    margin-bottom: 10px;
  }
}
</style>