<template>
  <div class="merchant-manage-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="860px"
      title="商户号配置"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="form"
        ref="dialogForm"
        :rules="formRule"
      >
        <el-row :gutter="20">
          <el-col :span="12"
            ><el-form-item label="商户号描述" prop="mchName">
              <el-input
                placeholder="请商户号描述"
                v-model="form.mchName"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="支付类型" prop="payType">
              <el-select
                style="width: 100%"
                v-model="form.payType"
                placeholder="请选择支付类型"
                :disabled="dataset.datas"
              >
                <el-option
                  v-for="item in paymentMethods"
                  :value="item.itemCode"
                  :label="item.itemName"
                  :key="item.itemCode"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="小程序appId" prop="appId">
              <el-input
                placeholder="请输入描述"
                v-model="form.appId"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="小程序秘钥" prop="appSecret">
              <el-input
                placeholder="请输入小程序秘钥"
                v-model="form.appSecret"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="商户号" prop="mchId">
              <el-input
                placeholder="请输入商户号"
                v-model="form.mchId"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="支付秘钥" prop="appletKey">
              <el-input
                placeholder="请输入支付秘钥"
                v-model="form.appletKey"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="24"
            ><el-form-item label="支付回调地址" prop="notifyUrl">
              <el-input
                placeholder="请输入支付回调地址"
                v-model="form.notifyUrl"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="24"
            ><el-form-item label="退款回调地址" prop="refundsNotifyUrl">
              <el-input
                placeholder="请输入退款回调地址"
                v-model="form.refundsNotifyUrl"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="24"
            ><el-form-item
              label="支付模块对应V3证书目录编号"
              prop="certificate"
            >
              <el-input
                placeholder="请输入商户号"
                v-model="form.certificate"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">取消</r-button>
        <r-button plain @click="submitHandler" :loading="submitLoading"
          >保存</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateMerchant } from "@/api/ruge/lego/config";

export default {
  name: "merchant-manage-dialog",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
    paymentMethods: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      submitLoading: false,
      form: {
        id: null,
        mchName: null,
        payType: null,
        appId: null,
        appSecret: null,
        mchId: null,
        appletKey: null,
        notifyUrl: null,
        refundsNotifyUrl: null,
        certificate: null,
      },
      formRule: {
        mchName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        payType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        appId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        appSecret: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  watch: {
    dataset: {
      handler(rowData) {
        if (rowData.datas) {
          const {
            mchName,
            payType,
            appId,
            appSecret,
            mchId,
            appletKey,
            notifyUrl,
            refundsNotifyUrl,
            certificate,
            id,
          } = rowData.datas;
          this.form.mchName = mchName;
          this.form.payType = payType;
          this.form.appId = appId;
          this.form.appSecret = appSecret;
          this.form.mchId = mchId;
          this.form.appletKey = appletKey;
          this.form.notifyUrl = notifyUrl;
          this.form.refundsNotifyUrl = refundsNotifyUrl;
          this.form.certificate = certificate;
          this.form.id = id;
        }
      },
      immediate: true,
    },
  },
  methods: {
    dialogCloseHandler(flag) {
      this.$emit("close", flag);
    },
    async submitHandler() {
      await this.$refs.dialogForm.validate();
      this.submitLoading = true;
      const params = { ...this.form };
      console.log("params", params);
      updateMerchant(params)
        .then(() => {
          this.$message.success("保存成功！");
          this.$emit("close", true);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.merchant-manage-dialog {
  .displayImgTip {
    font-size: 14px;
    color: #909399;
  }

  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 178px;
    width: 178px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .button-line {
    text-align: right;
    margin: 20px 0;
  }
}
</style>