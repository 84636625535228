var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container merchant-management-list" },
    [
      _c(
        "div",
        { staticClass: "button-line" },
        [
          _c(
            "r-button",
            { attrs: { plain: "" }, on: { click: _vm.addNewConfig } },
            [_vm._v("新增配置")]
          ),
          _c("r-button", { on: { click: _vm.initDatas } }, [_vm._v("刷新")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("FinalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _vm.merchantDialog.show
        ? _c("MerchantManageDialog", {
            attrs: {
              dataset: _vm.merchantDialog,
              paymentMethods: _vm.paymentMethods,
            },
            on: { close: _vm.dialogClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }